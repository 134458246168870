<template>
  <ConfirmationDialog
    title="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.CREATE.TITLE"
    label="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.CREATE.BTN"
    labelCancel="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.CREATE.CANCEL"
    labelConfirm="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.CREATE.CONFIRM"
    v-model="showDialog"
    tertiary
    dialogWidth="640px"
    color="primary"
    @confirm="save"
    @cancel="resetForm"
    :loading="isLoading"
  >
    <v-form v-on:submit.prevent ref="form" v-model="formValidation" lazy-validation>
      <SeminarTypeMappingForm v-model="seminarTypeMapping" />
    </v-form>
  </ConfirmationDialog>
</template>

<script>
import ConfirmationDialog from "@components/ConfirmationDialog/";
import SeminarTypeMappingForm from "@views/Restricted/SeminarManagement/SeminarTypeMappings/partials/SeminarTypeMappingForm.vue";
import formValidation from "@mixins/formValidation";
import { mapActions } from "vuex";

export default {
  name: "CreateSeminarTypeMapping",

  mixins: [formValidation],

  components: {
    ConfirmationDialog,
    SeminarTypeMappingForm,
  },

  data() {
    return {
      showDialog: false,
      seminarTypeMapping: this.initSeminarTypeMapping(),
      isLoading: false,
    };
  },

  computed: {
    params() {
      return {
        seminarTypeId: this.seminarTypeMapping.seminarTypeId,
        navisionEventAssignmentCode: this.seminarTypeMapping.navisionEventAssignmentCode,
        navisionDescription: this.seminarTypeMapping.navisionDescription,
      };
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    save() {
      this.validate();

      this.$nextTick(async () => {
        if (this.formValidation) {
          this.isLoading = true;
          try {
            await this.$axios.seminarManagement.createSeminarTypeMapping(this.params);

            this.showDialog = false;

            this.resetForm();

            this.$emit("reload");

            this.notify({
              type: "success",
              message: "SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.CREATE.SUCCESS",
            });
          } catch (err) {
            console.error(err);
            this.setValidationErrors(err);
          } finally {
            this.isLoading = false;
          }
        }
      });
    },

    resetForm() {
      this.seminarTypeMapping = this.initSeminarTypeMapping();
      this.resetValidation();
    },

    initSeminarTypeMapping() {
      return {
        seminarTypeId: null,
        navisionEventAssignmentCode: null,
        navisionDescription: null,
      };
    },
  },
};
</script>
