<template>
  <ConfirmationDialog
    v-model="dialog"
    icon="far fa-trash"
    title="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.DELETE.TITLE"
    body="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.DELETE.BODY"
    @confirm="deleteSeminarTypeMapping"
    tooltip="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.DELETE.TOOLTIP"
    confirmLabel="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.DELETE.CONFIRM"
    :loading="isLoading"
  />
</template>

<script>
import ConfirmationDialog from "@components/ConfirmationDialog/";

import { mapActions } from "vuex";

export default {
  name: "DeleteSeminarTypeMapping",

  components: {
    ConfirmationDialog,
  },

  data() {
    return {
      dialog: false,
      isLoading: false,
    };
  },

  props: {
    seminarTypeMappingId: {
      type: Number,
      required: true,
      default: null,
    },

    buttonSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async deleteSeminarTypeMapping() {
      this.isLoading = true;

      try {
        await this.$axios.seminarManagement.deleteSeminarTypeMapping({
          seminarTypeMappingId: this.seminarTypeMappingId,
        });
        this.$emit("reload");
        this.notify({
          type: "success",
          message: "SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.DELETE.SUCCESS",
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
