<template>
  <BaseAutoComplete
    :items="seminarTypes"
    item-text="displayName"
    item-value="id"
    label="FORM_ELEMENTS.SEMINAR_TYPE_SELECT_LABEL"
    :loading="isLoading"
    v-model="countryValue"
    v-bind="$attrs"
    v-on="$listeners"
    @clearValue="$emit('input', null)"
  />
</template>

<script>
export default {
  name: "SeminarTypeSelect",

  data() {
    return {
      isLoading: true,
      seminarTypes: [],
    };
  },

  props: {
    value: {
      type: undefined,
      required: true,
      default: () => [],
    },
  },

  computed: {
    countryValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    async listSeminarTypes() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.seminarManagement.listSeminarTypes({
          page: 1,
          perPage: 0,
        });
        this.seminarTypes = data.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.listSeminarTypes();
  },
};
</script>
