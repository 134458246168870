<template>
  <v-data-table
    :headers="headers"
    :items="seminarTypeMappings"
    v-bind="$attrs"
    v-on="$listeners"
    item-key="id"
  >
    <template #[`item.actions`]="{ item }">
      <v-row no-gutters>
        <v-spacer />
        <v-col
          cols="auto"
          :class="iconMargin"
          v-if="hasPermission({ permissionNames: ['seminar_type_mapping.update'] })"
        >
          <UpdateSeminarTypeMapping :seminarTypeMappingId="item.id" @reload="$emit('reload')" />
        </v-col>

        <v-col
          cols="auto"
          v-if="hasPermission({ permissionNames: ['seminar_type_mapping.delete'] })"
        >
          <DeleteSeminarTypeMapping :seminarTypeMappingId="item.id" @reload="$emit('reload')" />
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import UpdateSeminarTypeMapping from "@views/Restricted/SeminarManagement/SeminarTypeMappings/Update";
import DeleteSeminarTypeMapping from "@views/Restricted/SeminarManagement/SeminarTypeMappings/Delete";
import hasPermission from "@mixins/hasPermission";

export default {
  name: "SeminarTypeMappingsTable",

  mixins: [hasPermission],

  components: {
    UpdateSeminarTypeMapping,
    DeleteSeminarTypeMapping,
  },

  props: {
    seminarTypeMappings: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t(
            "SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.LIST.TABLE.HEADER.ASSIGNMENT_CODE"
          ),
          value: "navisionEventAssignmentCode",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.LIST.TABLE.HEADER.SEMINAR_TYPE"),
          value: "seminarType.displayName",
          sortable: false,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.LIST.TABLE.HEADER.DESCRIPTION"),
          value: "navisionDescription",
          sortable: true,
        },

        {
          value: "actions",
          align: "end",
          sortable: false,
        },
      ];
    },

    iconMargin() {
      return "mr-4 mr-md-0";
    },
  },
};
</script>
