<template>
  <v-row>
    <v-col cols="12" class="mb-n3">
      <SeminarTypeSelect v-model="formValue.seminarTypeId" required />
    </v-col>

    <v-col cols="12" class="mb-n3">
      <BaseTextField
        v-model="formValue.navisionEventAssignmentCode"
        label="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.CREATE.FORM.LABELS.ASSIGNMENT_CODE"
        required
      />
    </v-col>

    <v-col cols="12" class="mb-n3">
      <BaseTextField
        v-model="formValue.navisionDescription"
        label="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.CREATE.FORM.LABELS.DESCRIPTION"
      />
    </v-col>
  </v-row>
</template>

<script>
import SeminarTypeSelect from "@components/FormElements/SeminarTypeSelect.vue";

export default {
  name: "SeminarTypeMappingForm",

  components: {
    SeminarTypeSelect,
  },

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        seminarTypeId: null,
        navisionEventAssignmentCode: null,
        navisionDescription: null,
      }),
    },
  },

  computed: {
    formValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
