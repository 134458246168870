<template>
  <ConfirmationDialog
    title="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.UPDATE.TITLE"
    tooltip="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.UPDATE.TOOLTIP"
    labelCancel="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.UPDATE.CANCEL"
    labelConfirm="SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.UPDATE.CONFIRM"
    icon="far fa-pencil"
    v-model="showDialog"
    tertiary
    dialogWidth="640px"
    color="primary"
    @confirm="save"
    @cancel="resetForm"
    :loading="isLoading"
    :showContent="showContent"
  >
    <v-form v-on:submit.prevent ref="form" v-model="formValidation" lazy-validation>
      <SeminarTypeMappingForm v-model="seminarTypeMapping" />
    </v-form>
  </ConfirmationDialog>
</template>

<script>
import ConfirmationDialog from "@components/ConfirmationDialog/";
import SeminarTypeMappingForm from "@views/Restricted/SeminarManagement/SeminarTypeMappings/partials/SeminarTypeMappingForm.vue";
import formValidation from "@mixins/formValidation";
import { mapActions } from "vuex";

export default {
  name: "UpdateSeminarTypeMapping",

  mixins: [formValidation],

  components: {
    ConfirmationDialog,
    SeminarTypeMappingForm,
  },

  props: {
    seminarTypeMappingId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showDialog: false,
      showContent: false,
      seminarTypeMapping: this.initSeminarTypeMapping(),
      isLoading: false,
    };
  },

  watch: {
    showDialog(value) {
      if (!value) return;
      this.showSeminarTypeMapping();
    },
  },

  computed: {
    params() {
      return {
        seminarTypeId: this.seminarTypeMapping.seminarTypeId,
        navisionEventAssignmentCode: this.seminarTypeMapping.navisionEventAssignmentCode,
        navisionDescription: this.seminarTypeMapping.navisionDescription,
        seminarTypeMappingId: this.seminarTypeMappingId,
      };
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    save() {
      this.validate();

      this.$nextTick(async () => {
        if (this.formValidation) {
          this.isLoading = true;
          try {
            await this.$axios.seminarManagement.updateSeminarTypeMapping(this.params);

            this.showDialog = false;

            this.resetForm();

            this.notify({
              type: "success",
              message: "SEMINAR_MANAGEMENT.SEMINAR_TYPE_MAPPINGS.UPDATE.SUCCESS",
            });

            this.$emit("reload");
          } catch (err) {
            console.error(err);
            this.setValidationErrors(err);
          } finally {
            this.isLoading = false;
          }
        }
      });
    },

    async showSeminarTypeMapping() {
      this.showContent = false;

      try {
        const { data } = await this.$axios.seminarManagement.showSeminarTypeMapping({
          seminarTypeMappingId: this.seminarTypeMappingId,
        });

        this.seminarTypeMapping = data.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.showContent = true;
      }
    },

    resetForm() {
      this.seminarTypeMapping = this.initSeminarTypeMapping();
      this.resetValidation();
    },

    initSeminarTypeMapping() {
      return {
        seminarTypeId: null,
        navisionEventAssignmentCode: null,
        navisionDescription: null,
      };
    },
  },
};
</script>
